import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.flow
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable

fun main() {
	val flow = flow {
		emit("Hello")
		delay(2_000)
		emit("World")
		delay(2_000)
		emit("!")
	}

	renderComposable(rootElementId = "root") {
		val data by flow.collectAsState("")
		MainContainer {
			H3 {
				Text("Welcome to Compose dev test. ($data)")
			}


			Counter()

			A(
				href = "https://developer.android.com",
				attrs = {
					target(ATarget.Blank)
				},
			) {
				Text("Go to Android developer")
			}
		}
	}
}

@Composable
private fun MainContainer(content: @Composable () -> Unit) {
	Div(attrs = {
		style {
			height(100.percent)
			width(100.percent)
			backgroundImage(
				"""
				 linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
				 url(images/windmiles.jpeg)
			"""
			)
			backgroundSize("100% 100%")
			backgroundRepeat("no-repeat")
		}
	}) {
		content()
	}
}

@Composable
private fun Counter() {
	var count by remember { mutableStateOf(0) }

	Div(attrs = {
		style {
			margin(0.px)
			padding(25.px)

		}
	}) {
		LinkButton(
			text = "--",
			onClick = { count -= 1 },
			style = {
				color(rgb(255, 0, 0))
				fontSize(18.px)
			}
		)

		Span(attrs = {
			style {
				padding(15.px)
				fontSize(24.px)
			}
		}) {
			B {
				Text("$count")
			}
		}

		LinkButton(
			text = "++",
			onClick = { count += 1 },
			style = {
				color(rgb(0, 255, 0))
				fontSize(18.px)
			}
		)
	}
}

@Composable
private fun LinkButton(text: String, onClick: () -> Unit, style: StyleScope.() -> Unit = {}) {
	A(attrs = {
		style {
			style()
			property("user-select", "none")
		}
		onClick { onClick() }
	}) {
		B {
			Text(text)
		}
	}
}
